import { SenecaResponse } from "atfcore-commonclasses";
import { IApplicationScope } from "../../shared/global/applicationScope";

module CommonServices {
    var app = angular.module("app");

    export interface ICommonServicesControllerScope extends IApplicationScope {
        globalApplicationData: any;
    }

    export class CommonServicesController {
        static $inject = ["$scope", "$state", "$rootScope", "UserService", "$timeout", "toaster", "$translate", "$resource", "$window", "SessionStorageService", "RedirectManager", "$sessionStorage"];

        constructor(protected $scope: CommonServices.ICommonServicesControllerScope,
            protected $state: angular.ui.IStateService,
            protected $rootScope: any,
            protected UserService: any,
            protected $timeout: any,
            protected toaster: any,
            protected $translate: angular.translate.ITranslateService,
            protected $resource: ng.resource.IResourceService,
            protected $window: any,
            protected SessionStorageService: any,
            protected RedirectManager: any,
            protected $sessionStorage: any) {
        }

        // Esegue il logout e porta nella pagina di login
        public logout = () => {

            // Pulisco il salvataggio del url dal sessionstorage, affinché al prossimo login l'utente rientri nella pagina di default
            if (this.$sessionStorage.originalRequestedURL) {
                this.$sessionStorage.originalRequestedURL = null;
            }

            // Recupero i dati di SSO prima di eliminare il token
            let nameId = this.$scope.globalApplicationData.jwtPayload.params && this.$scope.globalApplicationData.jwtPayload.params.SAML_NAME_ID;
            let sessionIndex = this.$scope.globalApplicationData.jwtPayload.params && this.$scope.globalApplicationData.jwtPayload.params.SAML_SESSION_INDEX;
            // Se sono in SSO vado al logout specifico, altrimenti chiamo quello locale
            if (nameId && sessionIndex) {
                let urls = this.RedirectManager.getCustomersInvitationsSsoUrl();
                this.$window.location.href = urls.ssoRedirectLogoutPage + "?SAML_NAME_ID=" + encodeURIComponent(nameId) + "&SAML_SESSION_INDEX=" + encodeURIComponent(sessionIndex);
            }
            else {
                // Chiamo il Server per fare il logout locale
                this.UserService.logout.get().$promise
                    .then((data: SenecaResponse<any>) => {
                        // Se c'è segnalo l'errore
                        if (data.error) {
                            // Dati non validi, quindi alzo l'errore
                            this.toaster.pop("error", this.$translate.instant('error.generic.TITLE'), this.$translate.instant('error.generic.MESSAGE'));
                        } else {
                            // Rimuovo il token dalla sessione locale del browser
                            this.$scope.cleanJWT();
                            // e torno nella pagina di login
                            this.$state.go('app.localLogin');
                        }
                    })
                    .catch((error: Error) => {
                        var errors: Array<any> = [];
                        errors.push({ severity: "danger", message: this.$translate.instant("error.generic.UNKNOWN_ERROR") });
                        this.$rootScope.$emit("showApplicationModalErrors", errors);
                    });
            }
        }

    }
    app.controller("CommonServicesController", CommonServices.CommonServicesController);
}